import React from 'react';
import { Link } from 'gatsby'
import containerStyles from "./container.module.css"


const styles = theme => ({
  paper: {
    maxWidth: 1700,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  container: {
    padding: '48px 36px 0',
  },
})


class DirectorResume extends React.Component {
  render() {
    return (
      <div id="TopAlmost" className={containerStyles.container}>
        <body link="#C0C0C0" vlink="#808080" alink="#FF0000"></body>
        <div id="Profile">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>&#x2712;PROFESSIONAL PROFILE:</h1>
          <hr/><hr/>
          Long and productive history and extensive experience in Computer Systems Consulting, Software Analysis &amp; Engineering, Desktop Development, Trading Strategies & Ultra Low Latency Real-Time Development, Systems and Database Administration, Project and Relationship Management, ensures risk mitigation and the delivery of high-quality products over a variety of technology platforms and for a broad range of internal and external stakeholders.
          <br/>
          <br/>
          <hr/>

          <pre>{"\n"}</pre>
          <h1 style={{color: 'green'}}>&#x2654;KEY STRENGTHS:</h1>
          <hr/><hr/>
          <ul>
            <li> Educated and experienced in Management Information Systems</li>
            <li> Self-motivated and detail-oriented team player with a high service orientation and focus</li>
            <li> Efficient at working with concurrent projects in fast-paced environments</li>
            <li> Able to function productively under the pressure of tight deadlines and constant change</li>
            <li> Adept at managing relationships with a variety of counterparties and clients</li>
          </ul>
          <hr/>

          <pre>{"\n"}</pre>
          <h1 style={{color: 'green'}}>&#x1F4D6;EDUCATION:</h1>
          <hr /><hr />
          <table id="Degrees">
            <tbody>
              <tr>
                <td>&#x1F4D6; <b>M.B.A.</b>:</td>
                <td>YORK UNIVERSITY, Schulich School Of Business (1998, MIS - Toronto, Ontario, CANADA)</td>
              </tr>
              <tr>
                <td>&#x1F4D6; <b>M.Sc.</b>:</td>
                <td>DALHOUSIE UNIVERSITY, Faculty Of Graduate Studies (1984 - Halifax, Nova Scotia, CANADA)</td>
              </tr>
              <tr>
                <td>&#x1F4D6; <b>B.Sc.</b>:</td>
                <td>NATIONAL UNIVERSITY OF ATHENS,School of Physics &amp; Mathematics (1981 - Athens, GREECE)</td>
              </tr>
            </tbody>
          </table>
          <hr />

          <pre>{"\n"}</pre>
          <h1 style={{color: 'green'}}>&#x2697; PROFESSIONAL DIPLOMAS:</h1>
          <hr /><hr />
          <table id="Diplomas">
            <tbody>
              <tr>
                <td>&#x1F4D6; <b>Azure Fundamentals Certification</b>:</td>
                <td> CANADA (Microsoft Corporation)</td>
              </tr>
              <tr>
                <td>&#x1F4D6; <b>Software Engineering &amp; Structured Design</b>:</td>
                <td> ENGLAND (Polytechnic Of Central London)</td>
              </tr>
              <tr>
                <td>&#x1F4D6; <b>Software Testing: A Cost Effective Approach</b>:</td>
                <td> ENGLAND (Polytechnic Of Central London)</td>
              </tr>
              <tr>
                <td>&#x1F4D6; <b>Professional Study &amp; Research Diploma (Class A')</b>:</td>
                <td> GREECE (Ministry for Environment &amp; Public Works)</td>
              </tr>
            </tbody>
          </table>
          <hr />

          <pre>{"\n"}</pre>
          <h1 style={{color: 'green'}}>&#x2656; AWARDS AND DISTINCTIONS:</h1>
          <hr /><hr />
          <h3 style={{color: '#B8860B'}}>CANADA [Dalhousie University]</h3>
          <ul>
            <li> Teaching Scholarship</li>
            <li> Teaching &amp; Research Scholarship (Geophysics)</li>
            <li> Sir Izaak Walton Killam Memorial Scholarship (Nomination)</li>
          </ul>
          <h3 style={{color: '#B8860B'}}>GREECE</h3>
          <ul>
            <li> Geophysics multi-year scholarship for Graduate Studies abroad (National University of Athens)</li>
            <li> Two first-rank yearly university study scholarships (State Scholarships Institute)</li>
            <li> Two national praise certificates for academic excellence &amp; character (Ministry of Education).</li>
          </ul>
          <hr />

          <pre>{"\n"}</pre>
          <h1 style={{color: 'green'}}>&#x2656; LANGUAGES:</h1>
          <hr /><hr />
          Fluent in <b>English</b> (full professional proficiency), <b>Greek</b> (native proficiency). Old knowledge in <b>French</b>, <b>Esperanto</b>.
            <hr />
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>

        <div id="CurrentFocus">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>CURRENT FOCUS:</h1>
          <hr /><hr />
          <p>
            The current software development focuses on modern Web technology trends and life-long training and side project work in a variety of disciplines, including:
          </p>
          <ul>
            <li> <b>Fullstack Development</b> based on <b>NodeJS</b>, <b>JQuery</b>, <b>ExpressJS</b>, <b>AngularJS</b>, <b>ReactJS</b>, <b>Redux</b> (including
                 Thunk &amp; Saga Middleware), <b>MeteorJS</b>, <b>VulcanJS</b>, <b>GraphQL</b>, <b>ApolloJS</b>, <b>GatsbyJS</b> and <b>headless CMS</b> (Best Practices for <b>SPA</b>, <b>PWA</b> &amp; <b>RWD</b> applications development), <b>C# Core</b>, <b>Python</b>, <b>Xamarin</b> and <b>Apache Cordova</b></li>
            <li> <b>Neo4j</b> and <b>GraphQL: Graph Databases</b>, <b>Neo4j Browser</b>, <b>Neo4j Desktop</b> &amp; <b>Cypher Query Language</b> (including <b>OpenCypher</b>),
                 Data Structures with <b>APOC</b></li>
            <li> <b>JAMstack</b> (<b>Javascript</b>, <b>APIs</b> &amp; <b>Markup</b>) and <b>GRANDstack</b> (<b>GraphQL</b>, <b>ReactJS</b>, <b>ApolloJS</b> &amp; <b>Neo4j</b> Database)</li>
            <li> <b>GraphQL Servers</b> with <b>NodeJS</b>, <b>ReactJS</b> &amp; <b>MongoDB</b></li>
            <li> <b>Graphcool Framework</b> (<b>GraphQL Backend Framework</b>)</li>
            <li> <b>Restful API</b>s with <b>NodeJS</b>, <b>ExpressJS</b> &amp; <b>MongoDB</b></li>
            <li> <b>Cascading Style Sheets</b> (<b>CSS</b>)</li>
            <li> <b>Bootstrap</b></li>
            <li> <b>Webpack</b></li>
            <li> <b>Reactive</b> &amp; <b>Functional Programming</b></li>
          </ul>
          <hr />
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>

        <div id="ExperienceSynopsis">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>SYNOPSIS OF EXPERIENCE TO-DATE:</h1>
          <hr /><hr />
        </div>
        <div>
          <ul>
            <li> <b>MANAGEMENT OF SOFTWARE DEVELOPMENT TEAMS</b></li>
            <li> <b>SOFTWARE DEVELOPMENT</b> under <b>WINDOWS</b>, <b>UNIX</b>, <b>LINUX</b>, <b>MPE-XL</b>, <b>PRIMOS</b>, <b>NOVELL Netware</b>, <b>BANYAN
                 VINES</b>, <b>OS2</b>, <b>DOS</b> and various <b>microcomputer operating systems</b>, in a variety of software development environments</li>
            <li> <b>SYSTEMS ADMINISTRATION</b> under <b>XENIX</b>, <b>NOVELL</b> and <b>BANYAN VINES</b> operating systems</li>
            <li> <b>DATABASE ADMINISTRATION</b> in <b>SQL-SERVER</b>, <b>INFORMIX</b> and <b>FOXPRO</b></li>
            <li> <b>EXCELLENT KNOWLEDGE</b> of <b>office applications software</b> and their development, as well as <b>telecommunications protocols</b> and software
                 tools and experience with modern <b>Fullstack</b> and <b>GRANDstack Web Development</b> environments, including <b>NodeJS</b>, <b>JQuery</b>,
                 <b>ExpressJS</b>, <b>AngularJS</b>, <b>ReactJS</b>, <b>Redux</b>, <b>MeteorJS</b>, <b>VulcanJS</b>, <b>GraphQL</b>, <b>ApolloJS</b>, <b>Neo4j</b>, <b>Cypher Query Language</b>, <b>C# Core</b>, <b>.NET</b>, <b>Python</b>, <b>Xamarin</b>, <b>Apache Cordova</b>, <b>MongoDB</b> and other <b>NoSQL</b> as well as <b>relational databases</b></li>
          </ul>
          <hr />
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>
        <hr />

        <div id="Highlights">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>SOFTWARE DEVELOPMENT HIGHLIGHTS &amp; CONTINUING EDUCATION:</h1>
          <hr /><hr />
        </div>
        <div>
          <ul>
            <li> Certificate Courses in <b>Responsive Web Development</b>, <b>NodeJS</b> (2 courses), <b>Javascript</b>, <b>JQuery</b> (2
                 courses), <b>AngularJS</b>, <b>MongoDB</b>, <b>Ethical Hacking &amp; Penetration Testing</b>, <b>C++</b> (3 courses), <b>Ruby
                 Development</b>, <b>R</b> Programming Language, <b>IOS</b>, <b>Android Development</b>, <b>Git Mastering</b> (2 courses), <b>Copyright
                 Basics</b>, <b>Music Technology</b> (4 courses), <b>Electronic Music Production</b>, <b>Marketing &amp; Publishing</b> (4 courses)</li>
            <li> Online courses and advanced workshops and technical seminars covering: <b>RESTful API</b>s, <b>redis</b>, <b>Docker</b>, <b>Fullstack &amp;
                 GRANDStack Development</b> based on <b>NodeJS</b>, <b>JQuery</b>, <b>ExpressJS</b>, <b>AngularJS</b>, <b>ReactJS</b>, <b>Redux</b> (including
                 Thunk &amp; Saga Middleware), <b>MeteorJS</b>, <b>VulcanJS</b>, <b>GraphQL</b>, <b>ApolloJS</b>, <b>Neo4j</b>, <b>Graph Databases &amp;
                 Cypher Query Language</b>, <b>Graphcool Framework</b>, <b>C# Core</b>, <b>.NET</b>, <b>Python</b>, <b>Xamarin</b>, <b>Apache Cordova</b>, 
				 <b>CSS</b>, <b>Bootstrap</b> and <b>Webpack</b> among others</li>
            <li> <b>SQL</b>/<b>NoSQL DataBase Management Systems (DBMS)</b> including <b>SYBASE [ASE/IQ]</b>, <b>SQL-SERVER</b>, <b>MySQL</b>, <b>INFORMIX</b>, <b>db4o</b>, <b>SCULPTOR</b>, <b>FOXPRO</b>, <b>dBASE</b>, <b>MongoDB</b>, <b>redis</b></li>
            <li> Modern <b>IDE</b>s and frameworks (from <b>.NET Languages</b> on <b>VISUAL STUDIO</b> [<b>VISUAL BASIC</b>, <b>VB.NET</b>, <b>C#</b>, <b>ASP</b>]
                 to <b>JAVA Servlet</b> &amp; <b>JavaServer Pages</b> on <b>ECLIPSE</b> &amp; <b>RAVE</b>, to <b>NodeJS</b> on heterogeneous media)</li>
            <li> <b>4GL</b> &amp; <b>3GL Languages</b> (<b>JAVA</b>, <b>SPEEDWARE</b>, <b>INFORMIX</b>, <b>SCULPTOR</b>, <b>C</b>, <b>C++</b> [multiple environments including <b>Qt</b>], <b>PASCAL</b> &amp; <b>BASIC</b> [multiple versions from small computing machines to mid-range computers], <b>FORTRAN</b>)</li>
            <li> <b>Shell</b> &amp; <b>Scripting Languages</b> (<b>Tcl</b>/<b>Tk</b>, <b>PERL</b>/<b>Tk</b>, <b>CGI Perl</b>, <b>REBOL</b>, <b>VBScript</b>, <b>Javascript</b>, <b>Python</b>, <b>sh</b>, <b>csh</b>, <b>tcsh</b>, <b>ksh</b>)</li>
            <li> <b>UML</b> utilization in technical writing projects, <b>HTML</b>, <b>JSP</b>, <b>ASP</b>, <b>XML</b>, <b>JSON</b></li>
            <li> <b>MarketMind</b> (ITG proprietary rules-based language for auto-trading-strategies development)</li>
            <li> Experience with <b>TCP</b>/<b>IP</b>, <b>HTTP</b>, <b>FTP</b>, <b>TELNET</b>, <b>SLIP</b>, <b>PPP</b>, <b>SMTP</b>, <b>SNMP</b>, <b>DNS</b>, <b>UUCP</b>, <b>IPX</b> and <b>RS-232</b> protocols, as well as
                 with <b>CORBA</b> architecture, <b>IOS</b>, <b>Android</b>, <b>Git</b>, <b>Windows API</b>s, <b>PROLOG</b>, <b>LOGO</b>, <b>report writers</b>, <b>ASSEMBLY</b> (6502/6510, 8088/8086, 80x86), <b>Windows
                 registry scripts</b>, <b>Windows</b> &amp; <b>UNIX help compilers</b> and <b>application setup utilities</b>.</li>
          </ul>

          <pre>{"\n\n\n"}</pre>
          <hr />
          <h1 style={{ color: 'green' }}>SELECTED COURSES &amp; ADVANCED WORKSHOPS:</h1>
          <hr />
          <pre>{"\n"}</pre>

          <h3 style={{color: '#B8860B'}}>[1] COMPLETED, WITH CERTIFICATES (UDEMY):</h3>
          <hr />
          <ul style={{ listStyleType: 'square' }}>
            <li> MongoDB</li>
            <li> C++ Fundamentals (2 courses)</li>
            <li> Javascript, JQuery, Node.js, AngularJS (5 courses)</li>
            <li> Ruby Development</li>
            <li> R Programming Language</li>
            <li> Responsive Web Development</li>
            <li> Git Mastering (2 courses, including advanced)</li>
            <li> Ethical Hacking &amp; Penetration Testing</li>
            <li> Android Development</li>
            <li> IOS</li>
            <li> Bash Scripting</li>
            <li> Project Management Software</li>
            <li> Application Development with Docker</li>
            <li> Object Oriented PHP</li>
            <li> Joomla 3</li>
            <li> Copyright Basics</li>
            <li> Electronic Music Theory, Production, Marketing &amp; Publicity (5 courses)</li>
            <li> Ableton Live &amp; DJ (4 courses)</li>
            <li> Native Instruments Massive</li>
          </ul>
          <pre>{"\n"}</pre>

          <h3 style={{color: '#B8860B'}}>[2] COMPLETED (NodeUniversity):</h3>
          <hr />
          <ul style={{ listStyleType: 'square' }}>
            <li>React Native Quickly</li>
            <li>HTTP/2 with Node and Express</li>
            <li>React Foundation</li>
            <li>Node University Library</li>
            <li>Top ES6/ES2015 Features</li>
            <li>AWS Intro</li>
            <li>Node and MongoDB Foundation</li>
            <li>GraphQL + Apollo</li>
            <li>GraphQL Foundation</li>
            <li>Express Foundation</li>
          </ul>
          <pre>{"\n"}</pre>

          <h3 style={{color: '#B8860B'}}>[3] Online Communites &amp; Youtube Channels (A Multitude Of Courses):</h3>
          <hr />
          <ul style={{ listStyleType: 'square' }}>
            <li>GatsbyJS</li>
            <li>Bootstrap</li>
            <li>CSS</li>
            <li>GRAND-Stack</li>
            <li>MeteorJS</li>
            <li>VulcanJS</li>
            <li>AmplifyJS</li>
            <li>neo4j &amp; GraphQL</li>
            <li>SQLite</li>
            <li>QA Automation</li>
          </ul>
          <pre>{"\n"}</pre>

          <h3 style={{color: '#B8860B'}}>[4] Other Courses &amp; Workshops:</h3>
          <hr />
          <ul style={{ listStyleType: 'square' }}>
            <li>Windows Open System Architecture (WOSA)</li>
            <li>HP-UX Systems Administration</li>
            <li>SPEEDWARE 4GL</li>
            <li>NOVELL Network Administration</li>
            <li>Data Structures &amp; Non-Numerical Methods</li>
            <li>SCULPTOR 4GL/SQL</li>
            <li>Computability &amp; Complexity</li>
            <li>Computer Techniques Standardization</li>
            <li>Numerical Analysis / Computer Programming</li>
            <li>Language Processors / Compiler Design</li>
            <li>FIX Protocol Development</li>
          </ul>
          <pre>{"\n"}</pre>

          <h3 style={{color: '#B8860B'}}>[5] TECHNICAL SEMINARS &amp; CONGRESSES:</h3>
          <hr />
          <ul style={{ listStyleType: 'square' }}>
            <li>AWS Toronto Summit</li>
            <li>Neo4j Toronto GraphTour</li>
            <li>Integrated Business Solutions and Network Management</li>
            <li>Databases In Heterogenous &amp; Distributed Environments</li>
            <li>Desktop Publishing In Typography: Use Of Super Micros &amp; Advanced Laser Printers</li>
            <li>Business Management &amp; Information Science (Two Diplomas awarded).</li>
            <li>Applications On Digital Image Processing</li>
            <li>Document Management / Office Automation</li>
            <li>WWW Live &amp; Developer Nights (Microsoft)</li>
            <li>Peripherals &amp; Their Applications</li>
            <li>Turbo C++ Object-Oriented Programming</li>
            <li>Remote Sensing Using LANDSAT Data</li>
            <li>dBXL &amp; dBXL/LAN</li>
            <li>IBM OS/2</li>
            <li>NOVELL Netware</li>
            <li>IBM Token-Ring Network</li>
          </ul>
          <pre>{"\n"}</pre>

          <h3 style={{color: '#B8860B'}}>[6]	IN PROGRESS:</h3>
          <hr />
          <ul style={{ listStyleType: 'square' }}>
            <li>Selenium IDE</li>
            <li>Spanish</li>
            <li>French</li>
          </ul>
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>
        <hr />

        <div id="PreviousWorkAchievements">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>PREVIOUS WORK - ACHIEVEMENTS (SELECTED):</h1>
          <hr /><hr />
        </div>

        <div>

        <h2 style={{color: 'green'}}>INTELLECT COMPUTER SYSTEMS INC. - Director, Senior Software Engineering</h2>
          <hr />
          <b><u>SEP/2018 – Present</u></b>
          <hr />
          Currently focusing on the research and application of new technology trends and producing project work on:
          <ul>
            <li>Full Stack Development based on NodeJS, JQuery, ExpressJS, AngularJS, ReactJS, Redux, MeteorJS, VulcanJS, GraphQL, ApolloJS and headless CMS (Best Practices for SPA, PWA &amp; RWD applications development), C# Core, Python, Xamarin and Apache Cordova</li>
            <li>Neo4j and GraphQL: Graph Databases, Neo4j Browser, Neo4j Desktop &amp; Cypher Query Language</li>
            <li>JAMstack (Javascript, APIs &amp; Markup) and GRANDstack (GraphQL, ReactJS, ApolloJS &amp; Neo4j Database)</li>
            <li>GraphQL Servers with NodeJS, ReactJS &amp; MongoDB</li>
            <li>Graphcool Framework (GraphQL Backend Framework)</li>
            <li>Restful APIs With NodeJS, ExpressJS &amp; MongoDB</li>
            <li>Cascading Style Sheets (CSS), Bootstrap, Webpack</li>
            <li>Reactive &amp; Functional Programming</li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>INVESTMENT TECHNOLOGY GROUP - IT Consulting &amp; Software Development</h2>
          <hr />
          <b><u>FEB/2001 – AUG/2018</u></b>
          <hr />
          <table id="ITG">
            <tbody>
              <tr>
                <td><b><u>JUL/2011 - AUG/2018:</u></b></td>
                <td>Trading Servers &amp; FX Development (Core Infrastructure &amp; Routing Team)</td>
              </tr>
              <tr>
                <td><b><u>MAR/2010 - JUL/2011</u></b>:</td>
                <td>Team Leadership (Post-Trade Team)</td>
              </tr>
              <tr>
                <td><b><u>SEP/2003 - MAR/2010:</u></b></td>
                <td>Management (Desktop Tools)</td>
              </tr>
              <tr>
                <td><b><u>FEB/2001 - SEP/2003:</u></b></td>
                <td>Trading Strategy &amp; Development (Core Research Team, Infrastructure Team)</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <ul>
            <li> Served as Manager of Desktop Tools and Team Lead of the Post-Trade Team.</li>
            <li> Handled software analysis and development for the Post-Trade, Core Research, Algorithmic Trading Development, Sales &amp; Trading,
                 Finance, Compliance, Mid-Office and Core Infrastructure &amp; Routing Teams.</li>
            <li> Developed data-feeds, currency exposure &amp; FX applications, institutional trading strategies, original trading applications, 
                 <b>FIX</b> protocol products, user interfaces, client/server, market-data <b>API</b>s, database, Web and rich client reporting applications,
                 as well as monitoring and reconciliation tools on heterogeneous platforms.</li>
            <li> In addition, developed monitoring and reconciliation tools for the Research, Sales &amp; Trading, Finance, Compliance and Mid-Office departments
                 of the Firm.</li>
                <br/>
            <li><b>Skills Used:</b> Team Leadership &amp; IT Management, Full Stack Development, Architectural Analysis &amp; Design,
                  Object Oriented Design, SDLC, Agile Methodologies, Multithreading, Web Development, Low Latency &amp; Real-
                  Time Data Feeds &amp; Trading Systems Development for the Capital Markets, Equities &amp; Derivatives, Database
                  Applications Development, Statistical Data Analysis, Product Management, Market Data API Design &amp;
                  Implementation, Software Testing Automation [Selenium, Winium, Katalon, Appium], Continuous Integration
                  &amp; Continuous Delivery (CI/CD) [Jenkins], Issues/Bugs Tracking [JIRA, Rally, Bugzilla], IT Service Management
                  [ITSM], Project/Task Management &amp; Team Collaboration [Trello], Disaster Recovery.
                  <br/><br/>
                  A multitude of original products were created over the years on a variety of platforms, including:
                  <br/>
                  <ul>
                  <li><b>Windows:</b> Visual Basic, VB .NET, VBA, VBScript, ActiveX (OCX), C#, C, C++, STL, Python, R, Sybase ASE &amp;
                  IQ, Java On Sybase ASE, XML In Sybase, Sybase User-Defined SQL Functions, Microsoft SQL Server,
                  MarketData APIs, TCP/IP, User Datagram Protocol (UDP), NFS, ADO.NET, Apache Tomcat, OleDB, ODBC,
                  JDBC, NoSQL, MongoDB, HTML, ASP.NET, SQLite, REBOL, Ajax, Crystal Reports, STAMP Protocol, Reuters RFA
                  OMM, Npm, CSS, JavaScript, NodeJS, ExpressJS, ReactJS, Axios, Morgan, Winston, Chai, Mocha, Perl, Cygwin,
                  Grafana, KairosDB, Docker, Powershell Scripting, FIX Protocol Development, QuickFIX (HUGE Files Handler
                  Development), Ruby, XML, XSD, XAML, JSON, JSX, Joomla, Bootstrap, Babel, Webpack, Visio;</li>
                  <br/>
                  <li><b>Sun Solaris UNIX:</b> MarketMind, Java, C++, Qt, Shell Scripting;</li>
                  <br/>
                  <li><b>Linux:</b> C, C++, Awk, Gawk, Sed, Grep, Bash, Cshell, Ssh, Gcc, Tmux, Teamocil, Redis, Webdis, JSON, Yaml,
                  Git, FreeTDS, Sybase, Make/CMake, Codelite, CentOS, RedHat, REST.</li></ul>
            </li> 
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>UBS BANK CANADA - Technical Writing, Modeling (Software Systems Architecture)</h2>
          <hr />
          <b><u>NOV/1999 – FEB/2001</u></b>
          <ul>
            <li> Executed Modeling (<b>UML</b>), Technical Writing (including Systems Architecture) and prototype development for the <b>International Client
                 Reporting</b> &amp; <b>SmartSizing</b> (Overview &amp; Real-Time) projects.
              </li>
              <br/>
              <li><b>Skills Used:</b> Unified Modeling Language (UML) and Rational Rose, Visual Basic, ACTUATE Requester API Library, MS Access, ORACLE.</li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>TD BANK &amp; TD SECURITIES INC. - IT Consulting &amp; Software Development</h2>
          <hr />
          <b><u>JUL/1997 – JUL/1999</u></b>
          <ul>
            <li> Developed a custom, open-architecture, multi-currency, centralized, scalable and efficient application (<b>'Listed Equities &amp; Derivatives'</b>)
                 to deal with the data capture and reporting of the <b>Institutional Equities &amp; Structured Finance</b> trading community.</li>
            <li> Coded, tested, documented and maintained one third of the total number of the required pre-processors and related software tools for the
                 <b>'Risk Management Systems'</b> project, as well as database-management applications and a large number of utilities.</li>
                 <br/>
                 <li><b>Skills Used:</b> Full Stack Development with Visual Basic, C++, MS SQL SERVER, MS Access, Report Writers, ODBC Registry Scripting, 
                 Windows Help Compilers, Application Setup Utilities under Windows and Perl/Tk, SYBASE, make, rcs, pod, man, Risk management under Sun Solaris UNIX.</li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>COTT CORPORATION – Senior Technical Analysis &amp; Desktop Development</h2>
          <hr />
          <b><u>JUL/1995 – JUN/1997</u></b>
          <ul>
            <li> Led the global projects for <b>Hardware Testing &amp; Evaluation</b>, <b>Software Evaluation</b> (operating systems and applications),
                 <b>AntiVirus Strategy</b> &amp; <b>Software Distribution Tools</b>.</li>
            <li> Developed new software and maintained the corporate <b>Desktop Standard</b>.</li>
            <li> Provided backup assistance to the <b>SAP</b> and to the <b>BANYAN VINES</b> administrators.</li>
            <br/>
            <li><b>Skills Used:</b> Borland C/C++, Visual Basic (DOS), 'ini' Scripting, Policy Editors, Software Distribution Tools, Antivirus Testing under Banyan Vines, 
            MS-DOS and Windows as well as SAP Backup Utilities, Shell Scripting under HP-UX UNIX. </li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>SENECA COLLEGE OF APPLIED ARTS &amp; TECHNOLOGY – Teaching</h2>
          <hr />
          <b><u>MAY/1995 – OCT/1995</u></b>
          <ul>
            <li> Taught <b>Operating Systems</b> &amp; <b>UNIX</b> courses under <b>AIX UNIX</b> (including <b>Linux</b> references). </li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>SPEEDWARE CORPORATION – Programming/Analysis</h2>
          <hr />
          <b><u>JAN/1994 – JUL/1995</u></b>
          <ul>
            <li> Developed, tested and debugged parts of the <b>SPEEDWARE FINANCIALS</b> software packages.</li>
            <li> Ported applications and data between the different platforms in the Company.</li>
            <br/>
            <li><b>Skills Used:</b> Speedware 4GL &amp; CASE Tools, Media EIS and ALLBASE under HP/MPE-3000, MS-DOS and
            Windows, as well as C, Shell Scripts, Speedware 4GL, SCCS, awk, sed and ALLBASE under HP-UX and AIX UNIX. </li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>ROBBINS &amp; MYERS CANADA LTD. – Computer &amp; Database Systems Administration</h2>
          <hr />
          <b><u>DEC/1991 – DEC/1993</u></b>
          <ul>
            <li> Managed effectively the administration, security, fine-tuning and interoperability of three <b>XENIX</b> and one <b>NOVELL</b> Local Area Networks
                 from a hardware and software perspective.</li>
            <li> Developed efficient software structures to store and analyze pre-sales and sales information in a complex software and hardware environment.</li>
            <li> Performed critical statistical analysis on the data, to aid the short-term and long-term Corporate planning.</li>
            <li> Directed user support &amp; training and provided technical leadership and guidance.</li>
            <br/>
            <li><b>Skills Used:</b> C, Shell Scripting, Informix 4GL/SQL, FoxPro, Quick Basic, System Administration (Sysadmin) under SCO-XENIX, ALTOS-UNIX, DOS and NOVELL. </li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>J&amp;P (OVERSEAS) LTD. – MIS Department Management &amp; Software Development</h2>
          <hr />
          <b><u>JAN/1988 – OCT/1991</u></b>
          <ul>
            <li> Organized the Computer Department and introduced computerized techniques to assist the various departments of this <b>International
                 Building &amp; Civil Engineering</b> company.</li>
            <li> Performed a systems analysis/requirements analysis study and the <b>Network Administration</b> for a <b>NOVELL</b> Network.</li>
            <li> Developed and optimized a wide variety of database applications, using <b>fourth-generation</b> computer languages and <b>RDBMS</b> techniques
                 and performed software and hardware trouble-shooting and optimization.</li>
            <li> Developed original system utilities, screen and printer drivers, spreadsheet and <b>DTP</b> applications, and created efficient
                 business solutions to complement project management software.</li>
            <li> Generated systems documentation and evaluated new software packages &amp; machines.</li>
            <li> Directed extensive personnel training and support in three branches of the Company.</li>
            <br/>
            <li><b>Skills Used:</b> Sculptor 4GL/SQL, dBASE, C, Pascal, ASSEMBLY, Printer Driver Definitions, Font Utilities, Batch
            Scripting and Report Writers under DOS and NOVELL. </li>
          </ul>
          <hr />
          <pre>{"\n"}</pre>

          <h2 style={{color: 'green'}}>TETRAN HELLAS S.A. – EDP Management &amp; Computer Programming</h2>
          <hr />
          <b><u>FEB/1987 – JAN/1988</u></b>
          <ul>
            <li> Designed and implemented a database system for this new <b>Technology, Investment &amp; Development Institute</b> (partially funded by the government), in order to register the advanced production industry in Greece
              </li>
          </ul>
          <hr />
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
          <hr />
        </div>
        <div id="Extracurriculars">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>&#x2663; EXTRA-CURRICULUM ACTIVITIES:</h1>
          <hr /><hr />
        </div>
        <div>
          <ul>
            <li>&#x2663; Electronic/Computer Music Composition &amp; Production</li>
            <li>&#x2663; Habitat for Humanity, ITG TAC Outreach Program and other charity work</li>
          </ul>
          <hr />
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>
        <hr />

        <div id="References">
          <pre>{"\n\n\n"}</pre>
          <h1 style={{color: 'green'}}>REFERENCES:</h1>
          <hr /><hr />
        </div>
        <div>
          A number of persons will be glad to provide additional information on my character, my academic performance and my overall abilities, upon request.
            <hr />
          {/* <Link to="director#Top">≡ Top</Link> */}
          <a href="director#Top" style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">≡ Top</font></a>
        </div>
      </div>
    );
  }
};

export default DirectorResume;
