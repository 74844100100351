import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import DirectorContent from 'components/DirectorContent'

function DirectorPage({ location }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : ''

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<DirectorContent />
		</Layout>
	)
}

DirectorPage.propTypes = {
	location: PropTypes.object,
}

export default DirectorPage
