import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import TabBar from 'components/TabBar'
import { Link } from 'gatsby'
import DirectorResume from 'components/DirectorContent/DirectorResume.jsx'
import a_ref from './a_ref.css'
import DirectorHeader from '../../StaticQueries/DirectorHeader'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

// // Gatsby Typography
// import Typography from 'typography'
//
// import irvingTheme from 'typography-theme-irving'
// import altonTheme from 'typography-theme-alton'
// import elkGlenTheme from 'typography-theme-elk-glen'
// import lincolnTheme from 'typography-theme-lincoln'
// import sutroTheme from 'typography-theme-sutro'
// import wordpress2015Theme from 'typography-theme-wordpress-2015'
//
// const typography = new Typography(irvingTheme)


const typography = new Typography(irvingTheme)


const styles = theme => ({
	paper: {
		maxWidth: 1700,
		margin: 'auto',
		overflow: 'hidden',
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	searchInput: {
		fontSize: theme.typography.fontSize,
	},
	block: {
		display: 'block',
	},
	addUser: {
		marginRight: theme.spacing.unit,
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		padding: '48px 36px 0',
	}
})


// ========================================================================
// --- CV Sections --------------------------------------------------------
// ========================================================================
//
// 'Profile',
// 'Strengths',
// 'Education',
// 'Current Focus',
// 'Experience Synopsis',
// 'Previous Work - Achievements',
// 'Languages',
// 'Awards & Distinctions',
// 'Professional Diplomas',
// 'Software Development Highlights & Continuing Education',
// 'Extra-Curriculum Activities',
// 'References'
// ========================================================================

// const tabNames = ['Profile',	// Including 'Profile', 'Strengths', 'Education', 'Professional Diplomas', 'Awards & Distinctions', 'Languages'
// 	'Current Focus',
// 	'Experience Synopsis',
// 	'Previous Work - Achievements',
// 	'Highlights',
// 	'Extracurriculars',
// 	'References'
// ]

const listItems = [
	{ link: '#Profile', name: "Profile" },	// Including 'Profile', 'Strengths', 'Education', 'Professional Diplomas', 'Awards & Distinctions', 'Languages'
	{ link: '#CurrentFocus', name: "Current Focus" },
	{ link: '#ExperienceSynopsis', name: "Experience Synopsis" },
	{ link: '#PreviousWorkAchievements', name: "Previous Work - Achievements" },
	{ link: '#Highlights', name: "Software Development Highlights & Continuing Education" },
	{ link: '#Extracurriculars', name: "Extra-Curriculum Activities" },
	{ link: '#References', name: "References" }
];

function DirectorContent({ classes }) {
	return (
		<>
			<div className={classes.container}>
				<div style={{ display: "flex", backgroundImage: 'linear-gradient(red, yellow)', margin: "0" }} >
					<nav>
						<ul className={styles.headerMenu} style={{ display: "flex", flex: 1 }}>
							{listItems.map(item => (
								<li key={item.name} style={{ listStyleType: `none`, padding: `1rem` }} >
									<Link style={{
										color: `#fff`,
										textDecoration: 'none',
										backgroundColor: '#2fc974',
										boxShadow: '1px 5px 1px darkgreen',
										padding: '0.5em 0.5em',
										position: 'relative',
										textTransform: 'none',
										display: 'block',
										textAlign: 'center',
										borderRadius: '8px',
									}} to={`/director${item.link}`}> {item.name} </Link>
								</li>
							))}
						</ul>
					</nav>
				</div>

				<Paper className={classes.paper}>

					<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						{/* <h1 align="center" style={{color: 'green'}}>-= C°U°R°R°I°C°U°L°U°M ≡≡≡ V°I°T°A°E =-</h1> */}
						<DirectorHeader />
						<h2 align="center" style={{ color: 'blue' }}>Apostolos (Toli) STERGIOPOULOS</h2>
					</AppBar>

					<div className={classes.contentWrapper}  style={{fontSize: '110%', color: 'blue'}}>
						{/* <Typography color="textPrimary" align="justify"> */}
						<typography color="textPrimary" align="justify">
							<DirectorResume />
						</typography>
						{/* </Typography> */}
					</div>
				</Paper>
			</div>
		</>
	)
}

DirectorContent.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DirectorContent)
